// Migrated
<template>
  <div>
    <ResponsiveText class="box-title mb-6 md:mb-5">
      <span>
        {{ $t('lastminute') }}
      </span>
    </ResponsiveText>
    <OverflowFade
      class="charter-last-minute__offer white-bg w-full"
      container-classes="w-full"
    >
      <nuxt-link
        v-for="(lastminute, idx) in packages"
        :key="idx"
        class="flex flex-wrap offer"
        :to="lastminute.search_url"
      >
        <div class="w-7/12 flex flex-col md:flex-row md:items-center pl-0 sm:pl-4">
          <div class="offer-days pr-4 lg:pl-3 pt-4 pb-2 sm:pb-3">
            <div class="offer-day">
              <div class="month text-break">
                {{ capitalize(localeDate(lastminute.date, 'dddd DD MMM')) }}
              </div>
            </div>
          </div>
          <div class="px-0 sm:py-2 lg:px-3">
            <div class="trip-title mb-0 font-normal">
              <strong>
                {{ lastminute.name }}
              </strong>
              <span v-if="lastminute.days">
                {{ ' - ' }} {{ $t('daysText', lastminute.days) }}
              </span>
            </div>
            <span>
              {{ airportName(lastminute.airport) }}
            </span>
          </div>
        </div>
        <div class="w-5/12 inline-flex justify-end">
          <div class="offer-price-bar flex flex-col md:flex-row pr-0 lg:pr-3">
            <div class="md:mr-4 lg:mr-5 text-right py-4">
              <div class="flex items-end">
                <div
                  v-if="lastminute.isDiscounted"
                  class="text-nowrap mr-2 md:mr-3"
                >
                  <small>
                    {{ $t('shortFromPrice') }}
                  </small>
                  <small class="text-nowrap strikethrough">
                    {{ $n(lastminute.regular_pricepp) }}
                  </small>
                </div>
                <div
                  class="text-nowrap"
                  :class="{ 'text-red': lastminute.isDiscounted }"
                >
                  <small>
                    {{ $t('shortFromPrice') }}
                  </small>
                  <strong class="text-nowrap h5">
                    {{ $n(lastminute.price_sek) }}
                  </strong>
                </div>
              </div>
              <span class="-mt-1">
                {{ $t('perPerson') }}
              </span>
            </div>
            <div class="2xl:mt-2">
              <button class="btn-orange btn-lg btn-rounded px-6 lg:px-12">
                {{ $t('lastMinute.ChooseOffer') }}
              </button>
            </div>
          </div>
        </div>
      </nuxt-link>
    </OverflowFade>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { localeDate } = useDate()
    return {
      localeDate,
    }
  },
  computed: {
    ...mapState(useCharterStore, {
      startpage: 'startpage',
    }),

    ...mapState(useRootStore, {
      airports: 'charterAirports',
    }),

    promotedLastminuteTypesafe () {
      const items = this.startpage?.promoted_lastminute || []
      if (items && Array.isArray(items)) {
        return items
      } else if (items && items?.['1']) {
        return Object.values(items)
      }

      return []
    },

    packages () {
      return [...this.promotedLastminuteTypesafe]
        .sort((a, b) => this.$dayjs(a.date).unix() - this.$dayjs(b.date).unix())
        .map(p => ({
          ...p,
          name: p.name?.trim().split(',').slice(0, 2).join(', '),
          isDiscounted: p.price_sek !== p.regular_pricepp,
        }))
    },
  },

  methods: {
    capitalizeFirstLetter,

    airportName (airport) {
      return this.airports.find(a => a.airport === airport)?.airport_name
    },

    ...mapActions(useCharterPackagesStore, {
      searchDealfinder: 'searchDealfinder',
      activateLastMinute: 'activateLastMinute',
      search: 'search',
    }),
  },
})
</script>

<style lang="scss" scoped>
.charter-last-minute__offer {
  max-height: 690px;

  .offer-days {
    display: flex;
    gap: 1rem;
    width: 140px;

    .offer-day {
      display: flex;

      .day {
        line-height: 1;
        font-size: 2.5rem;
        font-weight: bold;
      }
      .day-name {
        line-height: 1;
        color: theme('colors.gray.DEFAULT');
      }
    }
  }

  .offer {
    border-top: 1px solid theme('colors.gray.DEFAULT');
    color: unset;

    &:hover {
      button {
        background: theme('colors.blue.DEFAULT');
        color: white;
      }
    }
  }

  .offer-price-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    position: relative;
    padding: 1.2rem 1rem;
    height: 100%;

    @media (max-width: calc(theme('screens.md') - 1px)) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      flex-direction: initial;
      justify-content: end;
    }
  }
}

.solresor-index__lastminute {
  h2.box-title.c-orange {
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1)
  }
  .charter-search__btn {
    height: auto;
    min-height: 120px;
  }
  &__change {
    background: theme('colors.orange.DEFAULT');
    margin: 1rem -1rem 0;
    font-size: .8em;
    padding: .5rem .75rem;
  }
}

.trip-title {
  .charter-last-minute__offer & {
    font-size: 1rem;
    line-height: 1.1;
  }
}
</style>
